exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-oferta-dla-ciebie-js": () => import("./../../../src/pages/oferta-dla-ciebie.js" /* webpackChunkName: "component---src-pages-oferta-dla-ciebie-js" */),
  "component---src-pages-projekty-js": () => import("./../../../src/pages/projekty.js" /* webpackChunkName: "component---src-pages-projekty-js" */),
  "component---src-pages-technologie-js": () => import("./../../../src/pages/technologie.js" /* webpackChunkName: "component---src-pages-technologie-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-aktualnosci-js": () => import("./../../../src/templates/aktualnosci.js" /* webpackChunkName: "component---src-templates-aktualnosci-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

